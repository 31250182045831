<template>
  <div class="els-grid">
    
  </div>
</template>

<script>
export default {
  props:{

  }
}
</script>

<style scoped lang="scss">
.els-grid {
  width:100%;
  height:100%;
  display:grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(24,1fr);
}
</style>